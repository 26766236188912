import { createSlice } from '@reduxjs/toolkit';

import {
  deleteTicket,
  fetchSingleTicket,
  replyOnTicket,
  updateTicket,
  updateTicketTabsOrder,
} from './actions';

const initialState = {
  data: null,
  fetching: true,
  isModalVisible: false,
  tabsOrder: {},
};

export const ticketSlice = createSlice({
  name: 'ticket',
  initialState,
  reducers: {
    setTicketReplyModalVisible: (state, { payload }) => ({ ...state, isModalVisible: payload }),
    storeTicketTabsOrder: (state, { payload }) => ({ ...state, tabsOrder: payload }),
    replaceTicket: (state, { payload }) => ({
      ...state,
      data: payload,
      fetching: false,
    }),
    resetTicketState: () => ({ ...initialState }),
  },
  extraReducers: {
    [fetchSingleTicket.pending]: (state) => ({ ...state, fetching: true }),
    [fetchSingleTicket.fulfilled]: (state, { payload }) => ({
      ...state,
      data: payload.ticket,
      tabsOrder: payload.ticketTabs,
      fetching: false,
    }),
    [fetchSingleTicket.rejected]: (state) => ({ ...state, fetching: false }),
    [deleteTicket.pending]: (state) => ({ ...state, fetching: true }),
    [deleteTicket.fulfilled]: (state, { payload }) => ({
      ...state,
      data: payload.id,
      fetching: false,
    }),
    [deleteTicket.rejected]: (state) => ({ ...state, fetching: false }),
    [replyOnTicket.pending]: (state) => ({ ...state, fetching: true }),
    [replyOnTicket.fulfilled]: (state) => ({ ...state, fetching: false, isModalVisible: false }),
    [replyOnTicket.rejected]: (state) => ({ ...state, fetching: false }),
    [updateTicket.fulfilled]: (state, { payload }) => ({
      ...state,
      data: payload.ticket,
    }),
    [updateTicketTabsOrder.fulfilled]: (state, { payload }) => ({ ...state, tabsOrder: payload }),
  },
});

export const { setTicketReplyModalVisible, replaceTicket, storeTicketTabsOrder, resetTicketState } =
  ticketSlice.actions;

export default ticketSlice.reducer;
